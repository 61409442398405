import React, { useEffect, useState } from 'react'
import SEO from '../../components/seo'
import Layout from '../../components/layout'
import HowItWorkBlock from '../../components/Blocks/HowItWorkBlock'
import SectionBoxReady from '../../components/Platfrom/SectionBoxReady'
import { DataCookieLess } from '../../../src/constants.js'
import SectionBoxCardText from '../../components/Platfrom/SectionBoxCardText'
import { FaqAccordionBlock } from '../../components/Blocks/AccordionBlock'
import HeroBlock from '../../components/Blocks/HeroBlock'
import QuoteBlock from '../../components/Blocks/QuoteBlock'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const dataCardText = [
     {
          title: 'Compliant, Out of the Box',
          desc: `ListenLayer is compliant with GDPR, CCPA, and other privacy regulations out of the box. Regional rules define how users are identified and how consent can be changed within their session.`,
     },
     {
          title: `Replace Your CMP`,
          desc: `Simplify your tech stack with ListenLayer's optional Consent Management Platform, or augment the CMP you already use with our integrations. Now, you can manage consent requirements at the source or truth rather than implementing and managing a separate tool like OneTrust, Cookiebot, or UserCentrics.`,
     },
     {
          title: `Go Cookieless!`,
          desc: `Gain all the benefits of local cookies in your analytics tracking without actually using them (and while respecting your users' privacy). `,
     },
     {
          title: `100% First-Party`,
          desc: `Implement a custom domain to transmit all analytics data within a first-party context.  `,
     },
]

const DataFAQs = [
     {
          cfFaq: {
               faqAnswer: `<p>ListenLayer uses a proprietary approach to remove the need for cookies from our service. We collect event information from your website and utilize edge technology to replace the need for cookies, while respecting the privacy of your users and avoiding the need to store anything on their device!"</p>\n`,
               faqQuestion: 'How does cookieless tracking work?',
          },
     },
     {
          cfFaq: {
               faqAnswer: `<p>ListenLayer operates based on regional consent rules that you can manage in the platform.  These rules govern how we can identify users and collect data from them based on where they originate from.  It's possible to use ListenLayer in a 100% cookieless mode, or allow for cookies in some regions where they are not limited. You can use our out-of-the-box rules or build your own. </p>\n`,
               faqQuestion: 'What is hybrid tracking?',
          },
     },
     {
          cfFaq: {
               faqAnswer: `<p>Most analytics solutions load resources and collect data over their own domain, making them "3rd party."  ListenLayer allows you to create a custom subdomain on your own website so you can load and transmit data in a first-party context.  This can improve speed, trust, and reliability - especially if you use ListenLayer's server-side tagging to remove the need for other analytics tools to run client-side.</p>\n`,
               faqQuestion: 'What is 100% first-party tracking?',
          },
     },
]

const heroFields = {
     h1: 'Cookieless Analytics is Here!',
     positioning: 'Left Justified',
     subText: '<p>Achieve cookieless without losing data or sacrificing quality, and while remaining compliant with privacy regulations.</p>\n',
     width: 'Full',
}

const quoteFields = {
     fieldGroupName: 'quote_fields',
     avatar: {
          mediaItemUrl: 'https://listenlayerdv.wpengine.com/wp-content/uploads/2021/01/Zach-Randall-Co-Founder_and_CEO.jpg',
          title: 'Zach-Randall-Co-Founder_and_CEO',
          mediaDetails: {
               sizes: [
                    {
                         name: 'medium',
                         sourceUrl: 'https://listenlayerdv.wpengine.com/wp-content/uploads/2021/01/Zach-Randall-Co-Founder_and_CEO-300x200.jpg',
                    },
                    {
                         name: 'large',
                         sourceUrl: 'https://listenlayerdv.wpengine.com/wp-content/uploads/2021/01/Zach-Randall-Co-Founder_and_CEO-1024x684.jpg',
                    },
                    {
                         name: 'thumbnail',
                         sourceUrl: 'https://listenlayerdv.wpengine.com/wp-content/uploads/2021/01/Zach-Randall-Co-Founder_and_CEO-150x150.jpg',
                    },
                    {
                         name: 'medium_large',
                         sourceUrl: 'https://listenlayerdv.wpengine.com/wp-content/uploads/2021/01/Zach-Randall-Co-Founder_and_CEO-768x513.jpg',
                    },
                    {
                         name: '1536x1536',
                         sourceUrl: 'https://listenlayerdv.wpengine.com/wp-content/uploads/2021/01/Zach-Randall-Co-Founder_and_CEO-1536x1025.jpg',
                    },
                    {
                         name: 'post-thumbnail',
                         sourceUrl: 'https://listenlayerdv.wpengine.com/wp-content/uploads/2021/01/Zach-Randall-Co-Founder_and_CEO-1200x801.jpg',
                    },
                    {
                         name: 'twentytwenty-fullscreen',
                         sourceUrl: 'https://listenlayerdv.wpengine.com/wp-content/uploads/2021/01/Zach-Randall-Co-Founder_and_CEO-1980x1322.jpg',
                    },
               ],
          },
     },
     detail: 'ListenLayer Founder,\r\nLifelong Digital Marketer & Data Nerd',
     name: 'Zachary W. Randall',
     quoteText: 'We invented a tracking solution that provides the benefits of cookies, without using them!',
}

const pageName = 'Cookieless'
const breadcrumbItems = [
     {
          name: 'Platform',
          url: `${'platform'}`,
     },
     {
          name: `${pageName}`,
     },
]

const Cookieless = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems} />

               <section className="cookieless-intro">
                    <div className="container">
                         <div className="row">
                              <div className="col-lg-7">
                                   <HeroBlock
                                        heroFields={heroFields}
                                        positioning={heroFields.positioning}
                                        h1={heroFields.h1}
                                        subText={heroFields.subText}
                                        width={heroFields.width}
                                   />
                              </div>
                              <div className="col-lg-5">
                                   <QuoteBlock
                                        quoteFields={quoteFields}
                                        quoteText={quoteFields.quoteText}
                                        avatar={quoteFields.avatar}
                                        name={quoteFields.name}
                                        detail={quoteFields.detail}
                                   />
                              </div>
                         </div>
                    </div>
               </section>

               <section className="cookieless-web">
                    <div className="container">
                         <HowItWorkBlock
                              firstContent={DataCookieLess.firstContent}
                              actions={DataCookieLess.actions}
                              secondContent={DataCookieLess.secondContent}
                         />
                    </div>
               </section>
               <SectionBoxReady />

               <SectionBoxCardText data={dataCardText} />
               <section className="cookieless-faq">
                    <div className="container">
                         <FaqAccordionBlock faqs={DataFAQs} buttonText={null} headline="Frequently Asked Questions" />
                    </div>
               </section>
          </Layout>
     )
}

export default Cookieless
